import jwt_decode from "jwt-decode";
import moment from "moment";

export default function isTokenValid(token) {
    let decoded = jwt_decode(token);
    let isExpired = moment(decoded.exp).utc().format()>moment.utc().format();
    if(isExpired){
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        location.reload();
    }
    return isExpired;
}