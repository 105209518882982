<template>
  <div>
    <CRow>
      <CCol lg="12">
        <div style="margin-bottom: 10px">
          <CForm ref="search_form" inline @submit.prevent="searchMeeting">
            <CInput placeholder="Search" v-model="search_text" size="lg" />
            <CButton
              color="light"
              size="lg"
              style="margin-left: 5px"
              v-on:click="searchMeeting"
            >
              <font-awesome-icon icon="search" />
            </CButton>
          </CForm>
        </div>
        <div class="user-table">
          <div class="table-content">
            <CDataTable
              :items="user_data"
              :fields="fields"
              hover
              :loading="loading"
              @row-clicked="rowClickHandler"
              :sorter="{ external: true, resetable: true }"
              :sorterValue.sync="sorterValue"
              id="dataTable"
            >
              <template #meeting_date="{ item }">
                <td>
                  {{ moment(item.meeting_date).format("LLL") }}
                </td>
              </template>
              <template #createdAt="{ item }">
                <td>
                  {{ moment(item.createdAtate).format("LLL") }}
                </td>
              </template>
                   <template #createdBy="{ item }">
                <td>
                      {{ item.created_by.username}}
                </td>
              </template>
                   <template #users="{ item }">
                <td>
                      {{ item.users_joined.length }}
                </td>
              </template>
              <!-- <template #password="{ item }">
                <td>
                  <div class="meeting-password-hide">
                    <span class="passord-field">
                      {{ item.password }}
                    </span>
                  </div>
                </td>
              </template> -->
            </CDataTable>
          </div>

          <CPagination :active-page.sync="currentPage" :pages="totalPages" />
        </div>
      </CCol>
    </CRow>
    <div
      style="
        position: absolute;
        top: 120px;
        right: 33px;
        z-index: 9999;
        height: 45px;
        min-width: 300px;
        border-radius: 10px;
      "
    >
      <CAlert
        style="color: red; background-color: white"
        :show.sync="currentAlertCounter"
        closeButton
      >
        Cannot find user!
      </CAlert>
    </div>
  </div>
</template>


<script>
import CTableWrapper from "../base/Table.vue";
import MeetingServiceApi from "../../services/api/meeting";

const fields = [
  "meetingcode",
  "meeting_date",
  "createdAt",
  "createdBy",
  "users",
  // "password",
  "meeting_ended",
];

export default {
  name: "ViewUser",
  components: { CTableWrapper },
  data() {
    return {
      fields,
      currentPage: 1,
      limit: 0,
      totalPages: 0,
      user_data: [],
      loading: true,
      search_text: null,
      currentAlertCounter: 0,
      sorterValue: { asc: true, column: null },
    };
  },

  mounted() {
    MeetingServiceApi.getAllMeeting().then(
      (response) => {
        this.loading = false;
        console.log(response.data);
        this.user_data = response.data.docs;
        this.limit = response.data.limit;
        this.totalPages = response.data.totalPages;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  watch: {
    currentPage: function (val) {
      this.loading = true;
      if (this.search_text && this.search_text.length) {
        MeetingServiceApi.searchMeeting(
          this.search_text,
          this.currentPage,
          this.sorterValue
        ).then(
          (response) => {
            if (response.data.message) {
              this.loading = false;
              this.user_data = response.data.message.docs;
            }
          },
          (error) => {
            this.loading = false;
            this.currentAlertCounter = 3;
          }
        );
      } else {
        MeetingServiceApi.getAllMeeting(
          this.currentPage,
          10,
          this.sorterValue
        ).then(
          (response) => {
            this.loading = false;
            this.user_data = response.data.docs;
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    sorterValue: function (val) {
      this.loading = true;
      if (this.search_text && this.search_text.length) {
        UserServiceApi.searchUser(this.search_text, 1, this.sorterValue).then(
          (response) => {
            if (response.data.message) {
              console.log(response.data.message);
              this.loading = false;
              this.currentPage = 1;
              this.user_data = response.data.message.docs;
              this.totalPages = response.data.message.totalPages;
            }
          },
          (error) => {
            this.loading = false;
            this.currentAlertCounter = 3;
          }
        );
      } else {
        console.log(this.sorterValue);
        MeetingServiceApi.getAllMeeting(
          this.currentPage,
          10,
          this.sorterValue
        ).then(
          (response) => {
            this.loading = false;
            this.user_data = response.data.docs;
          },
          (error) => {
            this.loading = true;
            this.currentAlertCounter = 3;
          }
        );
      }
    },
  },
  methods: {
    rowClickHandler(item, index, column, event) {
      const id = item._id;
      this.$router.push({ name: "MeetingDetails", params: { id } });
    },
    searchMeeting() {
      this.loading = true;
      if (this.search_text && this.search_text.length) {
        MeetingServiceApi.searchMeeting(this.search_text).then(
          (response) => {
            if (response.data.message) {
              this.loading = false;
              this.currentPage = 1;
              this.user_data = response.data.message.docs;
              this.totalPages = response.data.message.totalPages;
            }
          },
          (error) => {
            this.loading = false;
            this.search_text = null;
            this.currentAlertCounter = 3;
          }
        );
      } else {
        MeetingServiceApi.getAllMeeting().then(
          (response) => {
            this.loading = false;
            this.currentPage = 1;
            this.user_data = response.data.docs;
            this.totalPages = response.data.totalPages;
          },
          (error) => {
            this.loading = false;
            this.currentAlertCounter = 3;
          }
        );
      }
    },
  },
};
</script>
<style scoped>
.user-table {
  background-color: #fff;
  padding: 20px;
  margin: 0 10px 10px 10px 10px;
  border-radius: 20px;
}
.table-content {
  min-height: 550px;
}
.meeting-password-hide {
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 5px 10px;
}
.passord-field {
  text-align: center;
  display: block;
  width: 100%;
}

/* Rasha */
#dataTable {
  cursor: pointer;
}
</style>