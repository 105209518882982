import axios from 'axios'
import authHeader from '../auth/auth-header'


const API_URL = 'https://development.space-meets.com/api/'

class  MeetingServiceApi {
  getAllMeeting(page = 1, limit = 10,sort_values = { asc: true, column: null }) {
    var sort_value = 1;
    if (sort_values.asc) {
      sort_value = -1;
    }
    return axios.get(API_URL + 'conference/getAllMeetings', {
      params: {
        page: page,
        limit: limit,
        sortBy: sort_values.column,
        sortByValue: sort_value
      },
      headers: authHeader()
    });
  }
  searchMeeting(meetingId=null,userId=null,page = 1, limit = 10) {
    return axios.get(API_URL + 'conference/getAllMeetings', {
      params: {
        meetingId:meetingId,
        userId:userId,
        page: page,
        limit: limit,
      },
      headers: authHeader()
    });
  }
  getMeetingDetails(id){
    return axios.get(API_URL + `admin/meeting/meetingDetailById/${id}`,
    {
      headers: authHeader()
    });
  }
}

export default new MeetingServiceApi()