var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_c('CForm',{ref:"search_form",attrs:{"inline":""},on:{"submit":function($event){$event.preventDefault();return _vm.searchMeeting.apply(null, arguments)}}},[_c('CInput',{attrs:{"placeholder":"Search","size":"lg"},model:{value:(_vm.search_text),callback:function ($$v) {_vm.search_text=$$v},expression:"search_text"}}),_c('CButton',{staticStyle:{"margin-left":"5px"},attrs:{"color":"light","size":"lg"},on:{"click":_vm.searchMeeting}},[_c('font-awesome-icon',{attrs:{"icon":"search"}})],1)],1)],1),_c('div',{staticClass:"user-table"},[_c('div',{staticClass:"table-content"},[_c('CDataTable',{attrs:{"items":_vm.user_data,"fields":_vm.fields,"hover":"","loading":_vm.loading,"sorter":{ external: true, resetable: true },"sorterValue":_vm.sorterValue,"id":"dataTable"},on:{"row-clicked":_vm.rowClickHandler,"update:sorterValue":function($event){_vm.sorterValue=$event},"update:sorter-value":function($event){_vm.sorterValue=$event}},scopedSlots:_vm._u([{key:"meeting_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.moment(item.meeting_date).format("LLL"))+" ")])]}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.moment(item.createdAtate).format("LLL"))+" ")])]}},{key:"createdBy",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.created_by.username)+" ")])]}},{key:"users",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.users_joined.length)+" ")])]}}])})],1),_c('CPagination',{attrs:{"active-page":_vm.currentPage,"pages":_vm.totalPages},on:{"update:activePage":function($event){_vm.currentPage=$event},"update:active-page":function($event){_vm.currentPage=$event}}})],1)])],1),_c('div',{staticStyle:{"position":"absolute","top":"120px","right":"33px","z-index":"9999","height":"45px","min-width":"300px","border-radius":"10px"}},[_c('CAlert',{staticStyle:{"color":"red","background-color":"white"},attrs:{"show":_vm.currentAlertCounter,"closeButton":""},on:{"update:show":function($event){_vm.currentAlertCounter=$event}}},[_vm._v(" Cannot find user! ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }