import isTokenValid from "./is-token-valid"

export default function authHeader() {
  let token = JSON.parse(localStorage.getItem('token'))
  isTokenValid(token)
  if (token) {
    return { "x-access-token": token }
  } else {
    return {}
  }
}
